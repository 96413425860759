import React from 'react'
import capitalize from 'lodash/capitalize'
import { ProgressWidget, ProgressWidgetSkeleton } from '@revolut/ui-kit'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { formatPercentage } from '@src/utils/format'
import { useGoalsStats } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Details/GoalsStats/useGoalsStats'
import { GoalsOwner } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/GoalsModel'
import {
  getColorForPortion,
  widgetStylesOverrides,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Details/GoalsStats/styles'
import { GoalsLabel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Details/GoalsStats/GoalsLabel'
import pluralize from 'pluralize'

const goalsOwnerToEntityNameMap: Record<GoalsOwner, string> = {
  [GoalsOwner.Team]: 'team',
  [GoalsOwner.Department]: 'department',
}

const makeTitle = (entityName: string): string =>
  `${pluralize(capitalize(entityName))} goal progress`

interface Props {
  cycle: ReviewCyclesInterface
  goalsOwner: GoalsOwner
}

export const GoalsStats = ({ cycle, goalsOwner }: Props) => {
  const { totalCount, withGoalsCount, isLoading, isError } = useGoalsStats(
    cycle,
    goalsOwner,
  )
  const withGoalsPortion = withGoalsCount / totalCount

  const entityName = goalsOwnerToEntityNameMap[goalsOwner]
  const percentageLabel = totalCount > 0 ? formatPercentage(withGoalsPortion) : 'N/A'
  const title = makeTitle(entityName)
  const color = getColorForPortion(withGoalsPortion)

  if (isLoading) {
    return <ProgressWidgetSkeleton style={widgetStylesOverrides} />
  }

  return (
    <>
      {!isError && totalCount > 0 && (
        <ProgressWidget marginWidth={0} style={widgetStylesOverrides}>
          <ProgressWidget.Title>{title}</ProgressWidget.Title>
          <ProgressWidget.Description color={color}>
            {percentageLabel}
          </ProgressWidget.Description>
          <ProgressWidget.Progress value={withGoalsPortion} color={color} />
          <ProgressWidget.Label>
            <GoalsLabel
              entityName={entityName}
              withGoalsCount={withGoalsCount}
              totalCount={totalCount}
            />
          </ProgressWidget.Label>
        </ProgressWidget>
      )}
    </>
  )
}
