import React from 'react'
import { Icon, Token, DetailsCellSkeleton } from '@revolut/ui-kit'
import {
  useGetEngagementSurvey,
  useRefetchEngagementSurveyRounds,
  useUpdateEngagementSurvey,
} from '@src/api/engagement'
import { mapSurveyRoundStatusToColorV2 } from '@src/apps/People/Engagement/helpers'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { SurveyActions } from './SurveyActions'
import { SurveyOverview } from './SurveyOverview'
import { SurveyResultsAll } from './SurveyResultsAll'
import { isHubAppPath } from '../helpers/isHubApp'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import { SURVEYS_DEFAULT_ICON } from '@src/constants/common'
import { PermissionTypes } from '@src/store/auth/types'

const tabsConfig = [
  {
    title: 'Overview',
    icon: <Icon name="Profile" size={16} />,
    path: [
      ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_OVERVIEW,
      ROUTES.APPS.ENGAGEMENT.DETAILS_OVERVIEW,
    ],
    appUrl: ROUTES.APPS.ENGAGEMENT.DETAILS_OVERVIEW,
    url: ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_OVERVIEW,
    component: SurveyOverview,
  },
  {
    title: 'Results',
    icon: <Icon name="Questionnaire" size={16} />,
    path: [
      ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_RESULTS.ANY,
      ROUTES.APPS.ENGAGEMENT.DETAILS_RESULTS.ANY,
    ],
    appUrl: ROUTES.APPS.ENGAGEMENT.DETAILS_RESULTS.CATEGORIES,
    url: ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_RESULTS.CATEGORIES,
    component: SurveyResultsAll,
  },
]

export const SurveyDetail = () => {
  const { id } = useParams<{ id: string }>()
  const { data: surveyData, isLoading, refetch } = useGetEngagementSurvey(id)
  const { refetchEngagementSurveyRounds } = useRefetchEngagementSurveyRounds(id)
  const isHubApp = isHubAppPath()

  const tabs = tabsConfig.map(tab => ({
    ...tab,
    url: pathToUrl(isHubApp ? tab.appUrl : tab.url, { id }),
  }))

  if (!id || (!isLoading && !surveyData)) {
    return (
      <Redirect to={ROUTES[isHubApp ? 'APPS' : 'PERFORMANCE'].ENGAGEMENT.DASHBOARD} />
    )
  }

  const canEdit = !!surveyData?.field_options?.permissions?.includes(
    PermissionTypes.ChangeEngagementSurvey,
  )

  const onActionPerformed = () => {
    refetch()
    refetchEngagementSurveyRounds()
  }

  return (
    <PageWrapper>
      <PageHeader
        title={
          surveyData && (
            <PageHeader.Title
              title={surveyData.title}
              avatar={
                <EntityAvatar
                  data={surveyData}
                  defaultIcon={SURVEYS_DEFAULT_ICON}
                  canEdit={canEdit}
                  api={useUpdateEngagementSurvey}
                />
              }
              labels={
                <PageHeader.LabelsBar>
                  {surveyData.round_status && (
                    <PageHeader.Label
                      useTag
                      color={mapSurveyRoundStatusToColorV2(surveyData)}
                    >
                      {surveyData.round_status.name}
                    </PageHeader.Label>
                  )}
                </PageHeader.LabelsBar>
              }
              actions={<SurveyActions survey={surveyData} refetch={onActionPerformed} />}
            />
          )
        }
        backUrl={ROUTES[isHubApp ? 'APPS' : 'PERFORMANCE'].ENGAGEMENT.DASHBOARD}
        isLoading={isLoading}
      />
      <PageBody maxWidth={Token.breakpoint.xl} rowGap="s-16">
        <TabBarNavigation tabs={tabs} />
        {isLoading ? (
          <DetailsCellSkeleton />
        ) : (
          <Switch>
            {tabs.map(tab => (
              <Route path={tab.path} key={tab.title}>
                <tab.component survey={surveyData} refetch={onActionPerformed} />
              </Route>
            ))}
          </Switch>
        )}
      </PageBody>
    </PageWrapper>
  )
}
