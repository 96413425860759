import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

type NavigationFunction = (cycle: ReviewCyclesInterface) => () => void

interface Navigation {
  toPerformanceSummary: NavigationFunction
}

const performanceSummaryPath = ROUTES.PERFORMANCE.REVIEWS_CALIBRATION

export const useNavigation = (): Navigation => {
  const toPerformanceSummary =
    ({ offset }: ReviewCyclesInterface) =>
    () => {
      navigateTo(
        pathToUrl(
          performanceSummaryPath,
          {},
          offset !== undefined ? { cycle__offset: offset.toString() } : {},
        ),
      )
    }

  return { toPerformanceSummary }
}
