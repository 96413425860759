import React from 'react'
import pluralize from 'pluralize'
import { getColorForPortion } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Details/GoalsStats/styles'
import { Text } from '@revolut/ui-kit'

interface Props {
  entityName: string
  totalCount: number
  withGoalsCount: number
}

export const GoalsLabel = ({ entityName, withGoalsCount, totalCount }: Props) => {
  const color = getColorForPortion(withGoalsCount / totalCount)
  const description = `of ${totalCount} ${pluralize(
    entityName,
    withGoalsCount,
  )} ${pluralize('have', withGoalsCount)} provided goals`
  const noEntitiesDescription = `Your organisation has no ${pluralize(entityName)} set up`

  if (totalCount === 0) {
    return <Text>{noEntitiesDescription}</Text>
  }

  return (
    <>
      <Text color={color}>{withGoalsCount}</Text> <Text>{description}</Text>
    </>
  )
}
