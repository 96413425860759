import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiWithoutHandling } from './index'
import { API, PerformanceTimeRange } from '../constants/api'
import { GetRequestInterface, RequestInterface, RequestInterfaceNew } from '../interfaces'
import {
  CreateDepartmentInterface,
  DepartmentGoalsStats,
  DepartmentInterface,
} from '../interfaces/deparment'
import { FetchDataQueryInterface } from '../interfaces/data'
import { AxiosPromise } from 'axios'
import { PerformanceChartCycles } from '../interfaces/chart'
import { getMonthsByRange } from '@src/utils/kpi'
import { KarmaInterface, KarmaStatsInterface } from '@src/interfaces/karma'
import { TeamNPS, TeamNPSStats } from '@src/interfaces/teams'
import { useFetch, useUpdate } from '@src/utils/reactQuery'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { CXSummaryInterface, SupportLoadBreakdownBy } from '@src/interfaces/cx'

export const departmentRequests: RequestInterface<DepartmentInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.DEPARTMENT, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.DEPARTMENT}/${id}`),
  patchItem: async (data, kpiId) => api.patch(`${API.DEPARTMENT}/${kpiId}`, data),
  deleteItem: async id => api.delete(`${API.DEPARTMENT}/${id}`),
  postItem: async data => api.post(API.DEPARTMENT, data),
  getExport: async (exportType, filterQuery) =>
    api.get(`${API.DEPARTMENT}/${exportType}`, {
      params: filterQuery,
      responseType: 'blob',
    }),
}

export const departmentRequestsNew: RequestInterfaceNew<DepartmentInterface> = {
  get: async ({ id }) => api.get(`${API.DEPARTMENT}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.DEPARTMENT}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.DEPARTMENT, data),
  delete: async ({ id }) => api.delete(`${API.DEPARTMENT}/${id}`),
}

export const silentDeleteDepartment = async ({ id }: { id: number }) =>
  apiWithoutHandling.delete(`${API.DEPARTMENT}/${id}`)

export const useGetDepartment = (id?: number | string) => {
  return useFetch<DepartmentInterface>(id ? `${API.DEPARTMENT}/${id}` : null)
}

export const useUpdateDepartment = () =>
  useUpdate<DepartmentInterface, DepartmentInterface>(
    API.DEPARTMENT,
    undefined,
    undefined,
    false,
    (_oldData, newData) => {
      return newData
    },
    true,
  )

export const updateDepartmentStatistics = (id: string | number) => {
  return api.patch(`${API.DEPARTMENT}/${id}/statistics`)
}

export const departmentCXSummaryRequests =
  (id: number | string): RequestInterface<CXSummaryInterface>['getItems'] =>
  ({ sortBy, filters, page }) =>
    api.get(`${API.DEPARTMENT}/${id}/cx/summary`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const departmentCXComplaintsGraph = (
  id: number | string,
  range: PerformanceTimeRange,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.DEPARTMENT}/${id}/cx/complaints/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
    },
  })

export const departmentCXCsatDetractorsGraph = (
  id: number | string,
  range: PerformanceTimeRange,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.DEPARTMENT}/${id}/cx/csatDetractors/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      chart_type: 'percentage',
    },
  })
export const departmentCXSupportLoadGraph = (
  id: number | string,
  range: PerformanceTimeRange,
  breakdown?: SupportLoadBreakdownBy,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.DEPARTMENT}/${id}/cx/supportLoad/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      breakdown,
    },
  })

export const getKpiPerformanceGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.DEPARTMENT}/${id}/kpiPerformance`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
    },
  })

export const getDepartmentKarma =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<KarmaInterface>> =>
    api.get(`${API.DEPARTMENT}/${id}/karma`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getDepartmentKarmaStats =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<KarmaStatsInterface> =>
    api.get(`${API.DEPARTMENT}/${id}/karma/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getDepartmentNPSGraph =
  (teamId: number | string) =>
  (
    _: string,
    range: PerformanceTimeRange = PerformanceTimeRange.month,
  ): AxiosPromise<PerformanceChartCycles> =>
    api
      .get<PerformanceChartCycles>(`${API.DEPARTMENT}/${teamId}/nps/history`, {
        params: {
          date_partitioning: range,
          months: getMonthsByRange(range),
        },
      })
      .then(resp => {
        return {
          ...resp,
          data: {
            progress_history: resp.data.progress_history.map(item => ({
              ...item,
              progress: item.progress && item.progress * 100,
              estimated: item.estimated && item.estimated * 100,
            })),
            targets: resp.data.targets,
          },
        }
      })

export const getDepartmentNPSStats =
  (id: number | string) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface): AxiosPromise<TeamNPSStats> =>
    api.get(`${API.DEPARTMENT}/${id}/nps/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getDepartmentNPSDetractors =
  (id: number | string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<TeamNPS>> =>
    api.get(`${API.DEPARTMENT}/${id}/nps/detractors`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useGetDepartmentApprovals = (id?: number | string) => {
  return useFetch<ApprovalFlowResponse>(id ? `${API.DEPARTMENT}/${id}/approvals` : null)
}

export const createDepartment = (data: CreateDepartmentInterface) =>
  apiWithoutHandling.post(API.DEPARTMENT, data)

export const useGetDepartmentGoalsStats = (
  cycleId?: string | number,
  hasGoals?: boolean,
) =>
  useFetch<GetRequestInterface<DepartmentGoalsStats>>(
    `${API.DEPARTMENT}/goalStats`,
    'v1',
    {
      params: { cycle__id: cycleId, has_goals: hasGoals },
    },
  )
